
.faq-division{
    margin-top: 30px;
}
.faq-division>h1{
    font-size: var(--title-font);
    font-weight: bold;
    text-align: center;
    font-size: 35px;
    /* background-color: var(--principal-color);
    border-radius: 25px; */
} 

.faq-division{
    background-color: #F3EFEE;
    padding: 20px;
}
.faq-division>ul>li>a{
    text-transform: uppercase;
    font-size: 15px;
    font-family: var(--text-font);
    color:black;
    font-weight: bold;
    
}

.faq-division>ul>li>a:hover,.faq-division>ul>li>a:focus{
    color:black;
}

.faq-division>ul>li>div>p{
    
    font-size: 15px;
    font-family: var(--text-font);
    color:black;
    text-align: justify;

}


@media only screen and (max-width: 600px) {

    .faq-division>h1{
        font-size: 25px;
    } 

    .faq-division>ul>li>a{
        font-size: 13px;
        
    }

    .faq-division>ul>li>div>p{
    
        font-size: 13px;
    }
    
}

@media only screen and (max-width: 768px) {

}