.cc-container {
  padding: 40px;
}

.cc-result > div {
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 50%;
  background-size: cover;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 15px;
}
.cc-result > div > * {
  color: white;
}

.cc-result > div > div > div > * {
  color: white;
}

@media only screen and (max-width: 768px) {
  .cc-result > div {
    width: 100%;
    height: 100%;
  }
}
