
@import url(https://fonts.googleapis.com/css2?family=Graduate&display=swap&family=Open+Sans&display=swap&family=Lato:wght@300&display=swap&family=Rock+Salt&display=swap&family=Libre+Baskerville&display=swap&family=Oswald:wght@500&display=swap);
:root{
  --principal-color: rgb(5,94,126);
  --principal-color-10:rgb(5,94,126,0.1);
  --principal-color-20:rgb(5,94,126,0.2);
  --principal-color-30:rgb(5,94,126,0.3);
  --principal-color-40:rgb(5,94,126,0.4);
  --principal-color-50:rgb(5,94,126,0.5);
  --principal-color-60:rgb(5,94,126,0.6);
  --principal-color-70:rgb(5,94,126,0.7);
  --principal-color-80:rgb(5,94,126,0.8);
  --principal-color-90:rgb(5,94,126,0.9);

  /* --principal-color: 	#00baba; */
  --secondary-color: 	#000000;
  --third-color:white;
  --fourth-color:#007c7c;

  --title-font:'Oswald', sans-serif;
  /* --title-font:'Graduate', cursive; */
  --text-font:'Open Sans', sans-serif;
  --nav-font:'Lato', sans-serif;
  /* --logo-font:'Rock Salt', cursive; */
  --logo-font:'Libre Baskerville', serif;
  --signature-font:'Rock Salt', cursive;;
}

p{
  text-align: justify;
}
.title{
  font-family: var(--title-font);
  text-transform: uppercase!important;
}

.text{
  font-family: var(--text-font);
  color: var(--third-color);
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

.flex-center{
  display: flex;
  justify-content: center;
  align-items: center;
}

a {
  text-decoration: none!important;
}

a:hover 
{
   text-decoration:none!important; 
   cursor:pointer;  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

