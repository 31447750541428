.fitandmindset-intro-wrapper {
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.8);
}

.fitandmindset-intro {
  width: 60%;
  padding: 20px;
}

.fitandmindset-intro > h1 {
  color: white;
}
.fitandmindset-intro > p {
  color: white;
}

.fitandmindset-intro > h1,
.fitandmindset-main > h1,
.fitandmindset-benefits > h1,
.fitandmindset-qa > h1 {
  font-size: 35px;
  font-family: var(--title-font);
}

.fitandmindset-intro > p,
.fitandmindset-main > p {
  font-size: 15px;
  font-family: var(--text-font);
  text-align: justify;
}
.fitandmindset-benefits {
  background-color: rgba(0, 0, 0, 0.9);
  padding: 50px 0;
}
.fitandmindset-benefits > h1 {
  text-align: center;
  color: white;
}
.fitandmindset-benefits > ul > li > h2 {
  text-align: justify;
  font-size: 20px;
  color: white;
}

.fitandmindset-benefits > ul {
  counter-reset: index;
  padding: 20px;
}

.fitandmindset-benefits > ul > li {
  counter-increment: index;
  display: flex;
  align-items: center;
  padding: 30px 30px;
  box-sizing: border-box;
}

.fitandmindset-benefits > ul > li::before {
  content: counters(index, ".", decimal-leading-zero);
  font-size: 1.5rem;
  text-align: right;
  font-weight: bold;
  min-width: 30px;
  padding-right: 12px;
  font-variant-numeric: tabular-nums;
  align-self: flex-start;
  background-image: linear-gradient(to bottom, var(--principal-color), white);
  background-attachment: fixed;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.fitandmindset-benefits > ul > li + .fitandmindset-benefits > ul > li {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.fitandmindset-benefits {
  margin: 50px 0;
}

.fitandmindset-topics {
  margin: 0 -40px;
}
.fitandmindset-topics > div > div > h1 {
  font-family: var(--title-font);
  color: white;
  font-size: 70px;
}

.fitandmindset-topics > div > div > h2 {
  font-family: var(--title-font);
  color: white;
  font-size: 20px;
}
.fitandmindset-topics > div > div {
  padding: 20px;
}

.fitandmindset-topics-eat {
  background: rgba(0, 0, 0, 0.9);
}

.fitandmindset-topics-eat-content > h3 {
  color: white;
  font-size: 20px;
  font-weight: bold;
}

.fitandmindset-topics-eat-content > ul > li > p {
  color: rgba(255, 255, 255, 0.8);
  font-size: 15px;
  text-align: justify;
}

.fitandmindset-topics-comp {
  background: var(--principal-color-70);
}

.fitandmindset-topics-comp > div > div > p {
  color: white;
}

.fitandmindset-platform {
  width: 100%;
  height: 300px;
  background-position: center;
  background-size: cover;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.7);
  padding: 20px;
}
.fitandmindset-platform > div > h1 {
  font-family: var(--title-font);
  color: white;
}

[class*="fitandmindset-topics-"] {
  padding: 40px;
}
.fitandmindset-platform > div > p {
  font-family: var(--text-font);
  color: white;
  font-size: 20px;
}
.book-cover {
  transition: 0.5s;
}
.book-cover:hover {
  width: 30%;
}

.free-ebook {
  border: 1px solid white;
  color: white;
}

@media only screen and (max-width: 768px) {
  .fitandmindset-topics > div > div > h1 {
    font-size: 40px !important;
  }
  .fitandmindset-intro {
    width: 80%;
  }

  .fitandmindset-intro > h1 {
    font-size: 28px;
  }

  .fitandmindset-benefits > ul > li > h2 {
    font-size: 15px;
  }

  .fitandmindset-benefits > ul > li {
    padding: 12px 0px;
  }

  .fitandmindset-benefits > div > ul > li > h2 {
    font-size: 15px;
  }

  .fitandmindset-platform {
    width: 100%;
    height: 400px;
  }
}
