.bmi-content{
    padding: 50px;
}


input[type="radio"]{
    margin: 0 5px 0 0;
}
.bmi-form-title>h1{
    font-family: var(--title-font);
}

.gender-radio{
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;

}
.gender-radio>.gender-image{
    border-radius: 50%;
    border: .5px solid var(--principal-color-70);
    padding: 10px;
    min-height: 80px;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: middle;
    margin-bottom: 20px;
}
.gender-radio>.gender-image>img{
    width: 50px;

    
}
.label-radio:first-child>input{
    margin: 0 10px 0 0px;
}
.bmi-form-form{
    padding: 0 100px;
}


.form-check-input{
    width: 500px;
}
.range>h3{
    font-size: 20px;
    color: rgb(0, 0, 0,0.7)
}


.bmi-result-wrapper{
    background: var(--principal-color-70);
    border-radius: 50px;
    padding: 0;
}

.bmi-result-wrapper>div{
    
    padding: 20px;
}

.bmi-result-title>h1{
    color: white;
    font-size: 50px;
    font-family: var(--text-font);
}

.bmi-result-result>h1{
    font-size: 100px;
    font-family: var(--title-font);
    color: white;
}
.bmi-result-comentary>h1{
    color: white;
    font-size: 20px;
    font-weight: bold;
    font-family: var(--text-font);
}
.bmi-result-comentary>p{
    color: white;
    font-family: var(--text-font);
}

.bmi-text {
    padding: 20px 40px;
}

.bmi-intro-content{
    padding: 0;
    margin:0!important;
}

.bmi-intro-content-text{
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0!important;
    
}
.bmi-intro-content-text h1{
    font-size: 80px;
}

.bmi-intro-content-text p{
    font-size: 15px;
}

.bmi-intro-content-img-right {
    padding: 0;
    background-size: cover;
    background-position: center;
    min-height: 200px;
    margin: 0!important;
    clip-path: polygon(45% 0, 100% 0, 100% 100%, 0 100%);
}
.bmi-intro-content-img-left{
    padding: 0;
    background-size: cover;
    background-position: center;
    min-height: 200px;
    margin: 0!important;
    clip-path: polygon(0 0, 45% 0, 100% 100%, 0 100%);
}

.bmi-intro-content:nth-child(1),.bmi-intro-content:nth-child(3){
    background-color: rgba(0, 0, 0, 0.9);
}
.bmi-intro-content:nth-child(1) h1,.bmi-intro-content:nth-child(3) h1,.bmi-intro-content:nth-child(1) p,.bmi-intro-content:nth-child(3) p{
    color: rgba(255, 255, 255, 0.973);
}
.bmi-intro-content:nth-child(2),.bmi-intro-content:nth-child(4){
    background-color: var(--principal-color-60)
}

.bmi-intro-content:nth-child(2) h1,.bmi-intro-content:nth-child(4) h1,.bmi-intro-content:nth-child(2) p,.bmi-intro-content:nth-child(4) p{
    color: rgba(0, 0, 0, 0.8)
}
@media only screen and (max-width: 768px) {
    .bmi-content{
        margin: 0;
        padding: 15px;
    }
    .bmi-result-title>h1{
        font-size:40px;
    }

    .bmi-result-result>h1{
        font-size:60px;
        font-family: var(--title-font);
        color: white;
    }

    .bmi-form-wrapper{
        padding: 0;
    }

    .bmi-form-form{
        padding: 0 ;
    }

    .bmi-text {
        padding: 0 10px;
    }

    .bmi-intro-content:nth-child(1) h1,.bmi-intro-content:nth-child(3) h1,.bmi-intro-content:nth-child(1) p,.bmi-intro-content:nth-child(3) p{
        color: rgba(255, 255, 255, 0.973);
    }
    .bmi-intro-content:nth-child(1),.bmi-intro-content:nth-child(3){
        background-color: rgba(0, 0, 0, 0.9);
    }
    .bmi-intro-content:nth-child(4),.bmi-intro-content:nth-child(2){
        background-color: var(--principal-color-60)
    }
    .bmi-intro-content:nth-child(2) h1,.bmi-intro-content:nth-child(4) h1,.bmi-intro-content:nth-child(4) p,.bmi-intro-content:nth-child(2) p{
        color: rgba(0, 0, 0, 0.8)
    }
    .bmi-intro-content-text h1{
        font-size: 20px;
    }
    
    .bmi-intro-content-text p{
        font-size: 12px;
    }

    .bmi-intro-content-img-left{
        order: 2;
    }
    
}