@import url(https://fonts.googleapis.com/css2?family=Graduate&display=swap&family=Open+Sans&display=swap&family=Lato:wght@300&display=swap&family=Rock+Salt&display=swap&family=Libre+Baskerville&display=swap&family=Oswald:wght@500&display=swap);

:root{
  --principal-color: rgb(5,94,126);
  --principal-color-10:rgb(5,94,126,0.1);
  --principal-color-20:rgb(5,94,126,0.2);
  --principal-color-30:rgb(5,94,126,0.3);
  --principal-color-40:rgb(5,94,126,0.4);
  --principal-color-50:rgb(5,94,126,0.5);
  --principal-color-60:rgb(5,94,126,0.6);
  --principal-color-70:rgb(5,94,126,0.7);
  --principal-color-80:rgb(5,94,126,0.8);
  --principal-color-90:rgb(5,94,126,0.9);

  /* --principal-color: 	#00baba; */
  --secondary-color: 	#000000;
  --third-color:white;
  --fourth-color:#007c7c;

  --title-font:'Oswald', sans-serif;
  /* --title-font:'Graduate', cursive; */
  --text-font:'Open Sans', sans-serif;
  --nav-font:'Lato', sans-serif;
  /* --logo-font:'Rock Salt', cursive; */
  --logo-font:'Libre Baskerville', serif;
  --signature-font:'Rock Salt', cursive;;
}

p{
  text-align: justify;
}
.title{
  font-family: 'Oswald', sans-serif;
  font-family: var(--title-font);
  text-transform: uppercase!important;
}

.text{
  font-family: 'Open Sans', sans-serif;
  font-family: var(--text-font);
  color: white;
  color: var(--third-color);
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

.flex-center{
  display: flex;
  justify-content: center;
  align-items: center;
}

a {
  text-decoration: none!important;
}

a:hover 
{
   text-decoration:none!important; 
   cursor:pointer;  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.bg-image {
  height: 500px;
  position: relative;
}

.bg-body {
  z-index: 2;
}

.header-body {
  z-index: 2;
}

.header-body > h1 {
  font-size: 50px;
  color: white;
  font-family: var(--title-font);
  text-transform: uppercase;
}

.header-body > h2 {
  font-size: 20px;
  color: white;
}

.homepage-header {
  padding: 0 300px;
}

/* style={{backgroundImage: "url(img/homepage-header.jpg)", filter:"brightness(70%)"}} */
.bg-image::before,
.bg-image-sticky::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;

  display: block;
  background-image: var(--bg);
  background-size: cover;
  background-repeat: no-repeat;

  width: 100%;
  height: 100%;

  /* -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px); */
  -webkit-filter: brightness(40%);
          filter: brightness(40%);
}

.bg-image::before {
  background-position: 0 25%;
}

.bg-image-sticky {
  position: relative;
  background-position: center;
}

.bg-image-sticky::before {
  background-attachment: fixed;
}

.button-primary {
  background-color: var(--principal-color);
}
.button-secondary {
  background-color: white;
  transition: 0.5s;
  border-radius: 25px;
}

.button-secondary:hover {
  background-color: black !important;
  color: white;
}

.button-secondary:active {
  background-color: black !important;
  color: white;
}
.button-secondary-invert {
  background-color: black;
  color: white;
  transition: 0.5s;
  border-radius: 25px;
}

.button-secondary-invert:hover {
  background-color: white !important;
  color: black;
}

.button-secondary-invert:focus {
  background-color: white !important;
  color: black;
}

/* .button-secondary-invert:visited{
  background-color: white!important;
  color: black;
}  */

/* .button-secondary-invert:visited{
  background-color: white!important;
  color: black;
} */
.button-primary:hover {
  background-color: var(--secondary-color);
}

.button-primary:disabled {
  background-color: var(--principal-color-30);
}

.large-button {
  display: flex;
  align-items: center;
  justify-content: center;
}
.shadow {
  box-shadow: -6px 0px 101px 28px rgba(0, 0, 0, 0.79);
  -webkit-box-shadow: -6px 0px 101px 28px rgba(0, 0, 0, 0.79);
  -moz-box-shadow: -6px 0px 101px 28px rgba(0, 0, 0, 0.79);
}

.socialmedia-icons {
  box-shadow: -6px 0px 101px 28px rgba(0, 0, 0, 0.79);
  -webkit-box-shadow: -6px 0px 101px 28px rgba(0, 0, 0, 0.79);
  -moz-box-shadow: -6px 0px 101px 28px rgba(0, 0, 0, 0.79);
  color: var(--secondary-color);
  background: var(--principal-color);

  width: 150px;
  height: 150px;
  border-radius: 50%;
  text-align: center;
  line-height: 100px;
  vertical-align: middle;
  padding: 30px;
}

.socialmedia-icons:hover {
  color: var(--principal-color);
  background: var(--secondary-color);
}

.socialmedia-icons:focus {
  color: var(--principal-color);
  background: var(--secondary-color);
}

.socialmedia-icons-text {
  font-size: 50px;
  color: white;
}

.short-about-me {
  position: relative;
  padding: 60px;
  margin: 0;
  box-shadow: inset 0 0 0 1000px var(--principal-color-70);
  overflow: hidden;
}

.short-about-me > div {
  padding: 0 200px;
}

.short-about-me > div > img {
  border-radius: 50%;
}

.short-about-me > div > p {
  color: var(--third-color);
  text-align: justify;
  font-family: var(--text-font);
}

.short-about-me > div > h1 {
  color: var(--third-color);
  text-align: justify;
  font-family: var(--title-font);
  text-transform: uppercase;
  font-size: 60px;
  text-align: center;
}

.short-about-me-seemore {
  border-radius: 50%;
  margin: 10px;
  position: relative;
}

.short-about-me-seemore > img {
  border-radius: 50%;
  position: relative;
}

.short-about-me-seemore-inside {
  content: "";
  background-color: black;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: 0%;

  transition: 0.2s ease-in;
}

.short-about-me-seemore-inside:hover {
  content: "";
  background-color: rgb(0, 0, 0, 0.7);
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: 100%;
  z-index: 2;
}

.help-card {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: inset 0 0 0 1000px rgb(0, 0, 0, 0.7);
  border-radius: 10px;

  transition: ease-in 0.3s;
}
/* .help-card:hover{
  box-shadow: inset 0 0 0 1000px var(--principal-color-70);
} */
.help-card > h1 {
  font-size: 150%;
  color: white;
  font-family: var(--nav-font);
  text-transform: uppercase;
  padding: 0 130px;
}
.help-card-title > h1 {
  font-size: 65px;
  font-family: var(--title-font);
  text-align: center;
  text-transform: uppercase;
}

.help-card-wrapper {
  background-color: #f3efee;
}

.footer-title {
  margin: 30px;
}

.footer-title > a {
  font-size: 30px;
  text-transform: uppercase;
  font-family: var(--logo-font);
  text-decoration: none;
}

.footer-title > p {
  font-size: 12px;
  font-family: var(--logo-font);
  text-align: justify;
  text-decoration: none;
  color: white;
}
.footer-nav > li > a {
  color: white;
}
.footer-title-social-media > h1 {
  font-size: 30px;
  color: white;
  font-family: var(--nav-font);
}

.footer-social-media {
  color: white;
}

.divider-large {
  padding: 20px;
  border-right: 2px solid white;
  height: 40%;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  .top-navbar {
    display: none;
  }

  .divider-large {
    border-right: none;
  }
}

@media only screen and (max-width: 768px) {
  .help-card-title > h1 {
    font-size: 40px;
  }
  .short-about-me > div > h1 {
    font-size: 40px;
  }
  .short-about-me > div {
    padding: 0;
  }

  .short-about-me {
    padding: 20px;
  }

  .homepage-header {
    padding: 0 30px;
  }

  .header-body h1 {
    font-size: 25px;
  }

  .header-body > h2 {
    font-size: 15px;
  }

  .bg-image::before {
    background-position: center;
  }

  .bg-image {
    height: 500px;
  }
}

.top-navbar{
    height: 40px;
    background-color: black;
    list-style-type: none;
    margin: 0;
}

.top-navbar-item>a{
color: white;
 margin: 0;

 text-decoration: none;
}
.top-navbar-item{
    margin:0;
}


.logo-navbar:hover{
    text-decoration: none;
}
.logo{
    font-family: var(--logo-font);
    text-decoration: none;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: bold;
  }
  
  .uk-navbar-item, .uk-navbar-nav>li>a, .uk-navbar-toggle {
      min-height: 60px;
  }
.bottom-navbar-nav>li>a{
    font-size: 20px;

    text-transform: uppercase;
    font-family: var(--nav-font);
    font-weight: bold;
}

#offcanvas-nav-primary>div>ul>li>a{
    color: white;
}
.uk-navbar-dropdown-nav>li>a{
    font-size: 15px!important;

    text-transform: capitalize;
    font-family: var(--nav-font);
}


.uk-nav-sub>li>a{
    font-size: 15px;
}
.divider{
    border-right: 2px solid white;
  height: 20px;
}

#bottom-navbar{
    background-color: white !important;
}

.uk-navbar-left a{
    color: black !important;
}

.uk-navbar-left>ul>li> a:hover{
    background-color: black;
    color: white !important;
}




.uk-navbar-dropdown{
    background-color: black;

}

.uk-navbar-dropdown a{
    color: white!important;
    
}

.uk-navbar-dropdown a:hover{
    color: grey!important;
    
}

.uk-parent>a{
    color: white!important;
}

.uk-offcanvas-bar{
    background-color: black;
}



@media only screen and (max-width: 600px) {
    .top-navbar{
        display:none;
    }
    .logo{
        font-size: 15px;
    }
}

.sliderForm {
    width: var(--width);
    display: flex;
    height: 100%;
    
  }
  
  .sliderForm > div {
    flex-direction: column;
    min-width: var(--slides);
  }
  
  @-webkit-keyframes fade {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes fade {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
.aintermitente-intro {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-position: center;
  background-size: cover;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.7);
  height: 400px;
}

.aintermitente-intro > * {
  color: white;
  padding: 0 200px;
  font-size: 15px;
  text-align: justify;
}

.aintermitente-intro > ul {
  padding: 0 400px;
}
.aintermitente-intro > ul > li {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.aintermitente-intro > ul > li > img {
  width: 50px;
}

.aintermitente-intro > h2 {
  font-size: 40px;
}

.aintermitente-content {
  background-size: cover;
  background-position: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.7);
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.aintermitente-content > * {
  color: white;
}

.aintermitente-tipos {
  background-position: center;
  background-size: cover;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.7);
}
.aintermitente-tipos > * {
  color: white;
}

.aintermitente-tipos > ul > li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}
.aintermitente-tipos > ul > li > h3 {
  color: white;
  font-weight: bold;
}

.aintermitente-beneficios {
  background-size: cover;
  background-position: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.7);
}

.aintermitente-beneficios > * {
  color: white;
}

.aintermitente-beneficios > ul > li {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.aintermitente-beneficios > ul > li > img {
  width: 50px;
}

.aintermitente-beneficios > ul {
  margin: 100px 0;
}

.aintermitente-beneficios > ul > li > p {
  font-family: var(--title-font);
}

.aintermitente-platform {
  margin: 100px 0;
}
.aintermitente-platform > * {
  color: white;
}

.aintermitente-platform > h1 {
  font-size: 30px;
}
@media only screen and (max-width: 768px) {
  .aintermitente-intro > * {
    color: white;
    padding: 0 20px;
    font-size: 15px;
    text-align: justify;
  }

  .aintermitente-intro > ul {
    padding: 0 20px;
  }

  .aintermitente-intro {
    height: auto;
  }

  .aintermitente-content {
    height: auto;
  }
}

.dpaleo-intro {
  background-position: center;
  background-size: cover;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.7);
}

.dpaleo-intro > p,
.dpaleo-intro > h1 {
  color: white;
}

.dpaleo-intro {
  padding: 50px 200px !important;
}

.dpaleo-intro > h1 {
  font-size: 80px;
}

.dpaleo-benefits {
  background-color: rgba(0, 0, 0, 0.7);
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.7);
  background-position: center;
  background-size: cover;

  height: 400px;
}

.dpaleo-benefits > ul > li > p,
.dpaleo-benefits > h2 {
  color: white;
}

.dpaleo-benefits > h2 {
  font-size: 60px;
}
.dpaleo-benefits > ul > li {
  font-size: 20px;
  text-align: justify;
  display: flex;
  justify-content: center;
  align-self: center;
  flex-direction: column;
}

.dpaleo-benefits > ul > li > img {
  width: 60px;
  align-self: center;
}
.dpaleo-recomend {
  background-color: rgba(0, 0, 0, 0.9);
  background-size: cover;
  background-position: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.7);
  text-align: center;
}

.dpaleo-recomend > p,
.dpaleo-recomend > h2,
.dpaleo-recomend {
  color: white;
}

.dpaleo-recomend > p {
  text-align: center;
}

.dpaleo-recomend > ul > li {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: justify;
}

.dpaleo-recomend > ul > li > img {
  margin-bottom: 30px !important;
  width: 100px;
}

@media only screen and (max-width: 768px) {
  .dpaleo-intro {
    padding: 50px 20px !important;
  }

  .dpaleo-intro > h1 {
    font-size: 60px;
  }

  .dpaleo-benefits {
    height: 900px;
  }
}

.zona-intro {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;

  box-shadow: inset 0 0 0 1000px rgb(0, 0, 0, 0.7);
  background-position: center;
  background-size: cover;
}

.zona-text {
  padding: 0 !important;
}

.zona-text > div {
  padding: 20px;
  background-position: center;
  background-size: cover;
  height: 400px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.5);
}

.dzona-platform {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 600px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.5);
  width: 100%;
}

.dzona-platform > h1 {
  color: white;
  font-size: 40px !important;
}

.dzona-platform > h1 {
  padding: 0 100px;
  font-size: 20px;
  text-align: center;
}

.zona-text > div > p {
  color: white;
  font-size: 16px;
}

.zona-text > div > h1 {
  color: white;
}
.dzona-list {
  padding: 0 50px !important;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.8);
}

.dzona-list > h1 {
  margin-bottom: 50px;
  color: white;
  font-size: 90px;
}

.dzona-list > ul > li > p {
  color: white;
  text-align: justify;
  font-size: 20px;
}

.dzona-list > ul > li > img {
  width: 80px;
}
.zona-text > p {
  font-size: 15px;
  color: black;
}

.platform {
  flex-direction: column;
}

.wave-d {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 70px;
  width: 100%;
  background: dodgerblue;
  z-index: -1;
}
.wave::before {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100% 90%;
  width: 51%;
  height: 75px;
  background-color: white;
  right: 0px;
  top: 35px;
}
.wave::after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100% 90%;
  width: 51%;
  height: 75px;
  background-color: dodgerblue;
  left: -8px;
  top: 25px;
}

.zona-intro > h1 {
  color: white;
  font-size: 50px;
  text-align: center;
}

.option-text {
  padding: 0 !important;
}

@media only screen and (max-width: 768px) {
  .zona-intro > h1 {
    font-size: 30px;
  }

  .dzona-platform > h1 {
    padding: 0 30px;
    font-size: 20px !important;
    text-align: center;
  }

  .dzona-list {
    padding: 0 20px !important;
    height: 1200px;
  }

  .dzona-list > h1 {
    font-size: 60px;
  }

  .zona-text > div {
    margin-top: 0 !important;
  }
}


.faq-division{
    margin-top: 30px;
}
.faq-division>h1{
    font-size: var(--title-font);
    font-weight: bold;
    text-align: center;
    font-size: 35px;
    /* background-color: var(--principal-color);
    border-radius: 25px; */
} 

.faq-division{
    background-color: #F3EFEE;
    padding: 20px;
}
.faq-division>ul>li>a{
    text-transform: uppercase;
    font-size: 15px;
    font-family: var(--text-font);
    color:black;
    font-weight: bold;
    
}

.faq-division>ul>li>a:hover,.faq-division>ul>li>a:focus{
    color:black;
}

.faq-division>ul>li>div>p{
    
    font-size: 15px;
    font-family: var(--text-font);
    color:black;
    text-align: justify;

}


@media only screen and (max-width: 600px) {

    .faq-division>h1{
        font-size: 25px;
    } 

    .faq-division>ul>li>a{
        font-size: 13px;
        
    }

    .faq-division>ul>li>div>p{
    
        font-size: 13px;
    }
    
}

@media only screen and (max-width: 768px) {

}
.weight-loss-intro {
  background-position: center;
  background-size: cover;

  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.7);
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.weight-loss-intro > p {
  color: white;
}

.weight-loss-intro > h1,
.weight-loss-intro > h2 {
  color: white;
}

.option-text {
  width: 100vw;
}

.weight-loss-content {
  padding: 0 !important;
  margin: 0 !important;
  background-color: rgba(0, 0, 0, 0.9);
  height: 350px;
}

.weight-loss-content > div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  background-position: center;
  background-size: cover;
  /* border: 1px black solid; */
  /* 
  box-shadow: -1px 14px 9px -3px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -1px 14px 9px -3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 14px 9px -3px rgba(0, 0, 0, 0.75); */
}

.weight-loss-content > div > * {
  color: white;
}

.weight-loss-function {
  margin: 0 !important;
}

.weight-loss-function > li {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
}

.weight-loss-function > li {
  background-position: center;
  background-size: cover;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.7);
}

.weight-loss-function > li > * {
  color: white;
}

/* .weight-loss-function > li:nth-child(1),
.weight-loss-function > li:nth-child(3) {
  background: rgba(0, 0, 0, 0.9);
}
.weight-loss-function > li:nth-child(2) {
  background: var(--principal-color-70);
} */

/* .weight-loss-function > li:nth-child(2) > p,
.weight-loss-function > li:nth-child(2) > h3 {
  color: black;
} */

.weight-loss-function > li > h3 {
  font-size: 40px;
}

.weight-loss-function > li > p {
  font-size: 20px;
  text-align: center;
}

/* .weight-loss-function > li:nth-child(1) > p,
.weight-loss-function > li:nth-child(3) > p {
  color: white;
}

.weight-loss-function > li:nth-child(1) > h3,
.weight-loss-function > li:nth-child(3) > h3 {
  color: white;
} */

.weight-loss-content-img {
  -webkit-clip-path: polygon(0 1%, 100% 0%, 100% 100%, 50% 100%);
          clip-path: polygon(0 1%, 100% 0%, 100% 100%, 50% 100%);
  min-height: 200px;
}
.img-2 {
  -webkit-clip-path: polygon(0 1%, 100% 0%, 50% 100%, 0 100%);
          clip-path: polygon(0 1%, 100% 0%, 50% 100%, 0 100%);
}

@media only screen and (max-width: 768px) {
  .weight-loss-intro {
    height: 500px;
  }

  .weight-loss-intro > p {
    font-size: 12px;
  }

  .weight-loss-content > div {
    margin: 0 !important;
  }

  .weight-loss-content {
    height: 100%;
  }
  .weight-loss-function {
    padding: 0;
  }

  .option-text > h1 {
    font-size: 30px;
  }

  .weight-loss-content-text {
    order: 1 !important;
  }

  .weight-loss-content-img {
    -webkit-clip-path: polygon(0 1%, 100% 0%, 100% 100%, 0 100%);
            clip-path: polygon(0 1%, 100% 0%, 100% 100%, 0 100%);
  }
}

.weight-loss-intro > h2{
    font-weight: bold;
}

.rendimiento-deportivo{
    box-shadow: inset 0 0 0 2000px rgba(0,0,0,0.3);
    height: 100vh;
}

.rendimiento-deportivo h2{
    text-align: center;
    color: white;
}
.rendimiento-deportivo p{
    color: white!important;
}
.rendimiento-deportivo{
    background-color: rgba(0,0,0,0.7)  ;
}

.rendimiento-deportivo-categories{
    background-position: center;
    background-size: cover;

    box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.7);
    padding: 20px;

    height: 450px;
}

.rendimiento-deportivo-categories h2 , .rendimiento-deportivo-categories p{
    color: white;
}

.rendimiento-deportivo-categories h2{
    text-align: center;
}

.rendimiento-deportivo-platform h1{
    color: white;
}
.hs-intro {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 100px !important;
  background-position: center;
  background-size: cover;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.8);
}

.hs-intro > h1 {
  font-size: 30px;
  color: white;
}

.hs-outro > h2 {
  font-size: 25px;
  color: white;
}

.hs-outro {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 25px !important;
  background-position: center;
  background-size: cover;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.8);
}

.platform-button > img {
  width: 100px;
  height: auto;
  transition: 0.5s;
}

.platform-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10%;
  position: relative;
  transition: 0.5;
}

.platform-button > p {
  font-family: var(--title-font);
}

.platform-button:hover > img {
  transform: rotate(360deg);
}

.platform-button:hover > img {
  transform: rotate(360deg);
}
.hs-values-content {
  padding: 20px !important;
}

.hs-values-img {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
}

.hs-values:nth-child(even) {
  background: rgba(0, 0, 0, 0.8);
}

.hs-values:nth-child(odd) {
  background: var(--principal-color-70);
}

.hs-values:nth-child(odd) > .hs-values-img {
  order: 2;
}
.hs-values {
  width: 100%;
  height: 350px;
  margin: 0 !important;
}
.hs-values > div > h2,
.hs-values > div > p {
  color: white;
}

.hs-values > div > h2 {
  font-family: var(--title-font);
  text-transform: uppercase;
}

@media only screen and (max-width: 768px) {
  .hs-intro > h1 {
    font-size: 20px;
    color: white;
  }
  .hs-intro {
    padding: 0 20px !important;
  }

  .hs-values {
    width: 100%;
    height: 700px;
    margin: 0 !important;
  }
  .hs-values:nth-child(even) > .hs-values-img {
    order: 2;
  }
  .hs-outro > h2 {
    font-size: 15px;
    color: white;
    margin: 10px;
  }

  .option-text {
    width: 100vw;
  }

  .hs-values-img {
    background-position: center;
    background-size: cover;
    height: 300px;
    align-self: flex-end;
  }
}

.option-wrapper {
  overflow-x: hidden;
  height: 100%;
  transition: all 2s;
}
.single-option-wrapper {
  padding: 20px;
}
.option-content {
  height: 100%;
  overflow-y: scroll;
  transition: all 2s;
}

.option-content > h1 {
  margin: 10px;
  font-size: 90px;
}

.option-content > h1 > img {
  width: 70px;
  cursor: pointer;
}
.option-content-header {
  box-shadow: inset 0 0 0 1000px rgb(0, 0, 0, 0.7);
  height: 400px !important;
  background-size: cover;
  background-position: center;
  flex-direction: column;
}

.option-content-header > p {
  padding: 0 200px;

  color: white;
}

.option-content-header > h1 {
  color: white;
  cursor: pointer;
}

.option-content-header > h1::before {
  content: "<";
}
.option-content > div {
  width: 100%;
  margin: 0;
  height: 100%;
  padding: 20px;
}
.option-content::-webkit-scrollbar {
  display: none;
}

.option-text {
  flex-direction: column;
}
.option-text > div {
  text-align: center;
  padding: 0;
  margin: 0;
}

.muscle-list {
  margin: 0 !important;
  background-position: center;
  background-size: cover;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.6);
}

.muscle-list > * {
  color: white !important;
}
.option-text > h1 {
  padding: 40px;
}

.option-text > p {
  padding: 0 200px;
  text-align: justify;
  color: black;
  font-size: 20px;
}

.option-text > a {
  border-radius: 25px;
}
.option {
  margin: 0;
  padding: 0;
  min-height: 300px;
  cursor: pointer;
  border-radius: 25px;
  background-size: cover;
  background-position: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.6);
  transition: all 0.5s;
}
.option:hover {
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.3);
}
.option > h1 {
  padding: 0;
  color: white;
  text-align: center;
}
.dark > h2,
.dark > p {
  color: white;
}
.dark > h2,
.blue > h2 {
  text-transform: uppercase;
}
.dark {
  background-color: rgba(0, 0, 0, 0.9);

  padding: 20px;
}

.blue {
  background-color: var(--principal-color-70);
  padding: 20px;
}
.blue > h2,
.blue > p {
  color: rgba(0, 0, 0, 0.9);
}

.muscle {
  padding: 0;
}

.muscle-list > img {
  width: 100px;
}
.muscle-list-wrapper {
  margin: 0 !important;
  padding: 0 !important;
  height: 500px;
}
.muscle-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px;
}
.muscle-list > ul {
  list-style-type: none;
  display: flex;
  padding: 0;
}

.muscle-list > ul > li {
  background-color: white;
  color: black;
  margin: 10px;
  border-radius: 25px;
  padding: 10px;
  font-family: var(--title-font);
  display: flex;
  justify-content: center;
  align-items: center;
}

.musculacion-platform {
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px !important;
  flex-direction: column;
  box-shadow: inset 0 0 0 1000px rgb(0, 0, 0, 0.5);
}

.musculacion-platform > * {
  color: white;
}
@media only screen and (max-width: 768px) {
  .option-content > h1 {
    margin: 10px;
    font-size: 40px;
  }
  .option-text > p {
    padding: 0 35px;
    text-align: justify;
  }
  .option-content-header > p {
    padding: 0 15px;
  }
  .option-content > h1 > img {
    width: 45px;
    cursor: pointer;
  }

  .dark > ul > li {
    font-size: 13px;
  }

  .muscle-list-wrapper {
    height: auto;
  }
}

/* 
.sobremi{
    position: relative;
}
.sobremi::before{
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,1) 14%, rgba(0,117,117,1) 95%);

  
    width: 100%;
    height: 100%;
  
    /* -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter:  opacity(0.8);
} */
.sobremi-p{
    padding: 0px 200px 20px 200px ;
    margin: 0;

}
.sobremi-p>p{
    font-size: 15px;
    text-align: justify;
    font-family: var(--text-font);
    color:black;


}
/* 
.sobremi-competitions>div>img{
    box-shadow: -6px 0px 101px 28px rgba(0,0,0,0.79);
    -webkit-box-shadow: -6px 0px 101px 28px rgba(0,0,0,0.79);
    -moz-box-shadow: -6px 0px 101px 28px rgba(0,0,0,0.79);
} */



.sobremi-competitions>h3{
    font-size: 20px;
    color: white;
    text-align: center;
    font-family: var(--title-font);
    text-shadow: 7px 2px 10px rgba(0, 0, 0, 1);
    background-size:cover;
    background-repeat: no-repeat;
    background-position: center;

 
}

.sobremi-competitions{

 padding:50px 50px;

 background-color: var(--principal-color);
 border-radius: 25px;
 background-size:cover;
 background-repeat: no-repeat;
 background-position: center;
    


 box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.7);


} 


.sobremi-evidence{

    padding-top: 40px;
    padding-bottom: 40px;


}
.sobremi-evidence>div>h1{
    font-size: 20px;
    font-family: var(--title-font);
    color: black;
  
}

.sobremi-evidence>div>img{
    width: 50%;
    
}
.sobremi-signature>h1{
    font-family: var(--signature-font) ;
    font-size:60px;
}


@media only screen and (max-width: 600px) {
    .sobremi-p{
        padding: 0px 30px 20px 30px;
    }

    
}

@media only screen and (max-width: 768px) {
    .sobremi-p{
        padding: 0px 30px 20px 30px;
    }

    .sobremi-evidence{
        padding-left: 0;
    }
    .sobremi-signature>h1{

        font-size:30px;
    }
}
.fitandmindset-intro-wrapper {
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.8);
}

.fitandmindset-intro {
  width: 60%;
  padding: 20px;
}

.fitandmindset-intro > h1 {
  color: white;
}
.fitandmindset-intro > p {
  color: white;
}

.fitandmindset-intro > h1,
.fitandmindset-main > h1,
.fitandmindset-benefits > h1,
.fitandmindset-qa > h1 {
  font-size: 35px;
  font-family: var(--title-font);
}

.fitandmindset-intro > p,
.fitandmindset-main > p {
  font-size: 15px;
  font-family: var(--text-font);
  text-align: justify;
}
.fitandmindset-benefits {
  background-color: rgba(0, 0, 0, 0.9);
  padding: 50px 0;
}
.fitandmindset-benefits > h1 {
  text-align: center;
  color: white;
}
.fitandmindset-benefits > ul > li > h2 {
  text-align: justify;
  font-size: 20px;
  color: white;
}

.fitandmindset-benefits > ul {
  counter-reset: index;
  padding: 20px;
}

.fitandmindset-benefits > ul > li {
  counter-increment: index;
  display: flex;
  align-items: center;
  padding: 30px 30px;
  box-sizing: border-box;
}

.fitandmindset-benefits > ul > li::before {
  content: counters(index, ".", decimal-leading-zero);
  font-size: 1.5rem;
  text-align: right;
  font-weight: bold;
  min-width: 30px;
  padding-right: 12px;
  font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
  align-self: flex-start;
  background-image: linear-gradient(to bottom, var(--principal-color), white);
  background-attachment: fixed;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.fitandmindset-benefits > ul > li + .fitandmindset-benefits > ul > li {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.fitandmindset-benefits {
  margin: 50px 0;
}

.fitandmindset-topics {
  margin: 0 -40px;
}
.fitandmindset-topics > div > div > h1 {
  font-family: var(--title-font);
  color: white;
  font-size: 70px;
}

.fitandmindset-topics > div > div > h2 {
  font-family: var(--title-font);
  color: white;
  font-size: 20px;
}
.fitandmindset-topics > div > div {
  padding: 20px;
}

.fitandmindset-topics-eat {
  background: rgba(0, 0, 0, 0.9);
}

.fitandmindset-topics-eat-content > h3 {
  color: white;
  font-size: 20px;
  font-weight: bold;
}

.fitandmindset-topics-eat-content > ul > li > p {
  color: rgba(255, 255, 255, 0.8);
  font-size: 15px;
  text-align: justify;
}

.fitandmindset-topics-comp {
  background: var(--principal-color-70);
}

.fitandmindset-topics-comp > div > div > p {
  color: white;
}

.fitandmindset-platform {
  width: 100%;
  height: 300px;
  background-position: center;
  background-size: cover;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.7);
  padding: 20px;
}
.fitandmindset-platform > div > h1 {
  font-family: var(--title-font);
  color: white;
}

[class*="fitandmindset-topics-"] {
  padding: 40px;
}
.fitandmindset-platform > div > p {
  font-family: var(--text-font);
  color: white;
  font-size: 20px;
}
.book-cover {
  transition: 0.5s;
}
.book-cover:hover {
  width: 30%;
}

.free-ebook {
  border: 1px solid white;
  color: white;
}

@media only screen and (max-width: 768px) {
  .fitandmindset-topics > div > div > h1 {
    font-size: 40px !important;
  }
  .fitandmindset-intro {
    width: 80%;
  }

  .fitandmindset-intro > h1 {
    font-size: 28px;
  }

  .fitandmindset-benefits > ul > li > h2 {
    font-size: 15px;
  }

  .fitandmindset-benefits > ul > li {
    padding: 12px 0px;
  }

  .fitandmindset-benefits > div > ul > li > h2 {
    font-size: 15px;
  }

  .fitandmindset-platform {
    width: 100%;
    height: 400px;
  }
}


.title-book{
    margin: 0 90px;
    padding-top: 40px;
}

.book-card{
    position: relative;
    width: 200px;
}
.book-card>img{
    position: relative;
}

.book-card>div{
    transition: 0.2s ease-in;
    height: 0%;
    width: 100%;
    background-color: rgb(0, 0, 0,0.7);
    opacity: 0;
    position: absolute;
    bottom: 0;
    visibility: hidden;
}

.book-card>div>div{
    padding: 20px;
}

.book-card>div>div>p,.book-card>div>div>h3{
    color: white!important;
    text-align: center;
}


.book-card>div>div>h3{
    font-size: 20px;
    font-family: var(--title-font);
    text-transform: uppercase;
}

.book-card>div>div>p{
    font-size: 25px;
    font-family: var(--text-font);
}

.book-card:hover>div{
    opacity: 1;
    height: 100%;
    width: 100%;
    visibility: visible;

}
.book-card:hover div>div{
    padding: 20px;
}

@media only screen and (max-width: 768px) {

    .books>div{
        padding: 0;
    }

    .title-book{
        margin: 0 50px;
        padding-top: 40px;
        text-align: center;
    }
}
.tabata-timer-wrapper{
    padding: 50px;
}



.tabata-timer-timer{
    background-color: var(--principal-color-70);

    width: 400px;
    height: 400px;
    margin: 0!important;
    border-radius: 100%;
 
}


.tabata-timer-timer>h1{
    color:white;
    margin: 0;
    font-family: var(--title-font);
    font-size: 150px;
}

.tabata-timer-mov{
    padding-top: 20px;
}

.tabata-timer-mov>h1{
    font-family: var(--title-font);
}

.tabata-timer-round>h1{
    font-family: var(--text-font);
    font-weight: bold;
}

.time-form-wrapper{
    padding: 40px;
}

.time-form-item{
    margin-bottom: 20px;
}
.time-form-title>h1{
    font-family: var(--title-font);
    font-size: 65px;
}

.uk-form-label{
    font-size: 20px;
}

.my-node-enter {
    opacity: 0;
  }
  .my-node-enter-active {
    opacity: 1;
    transition: opacity 200ms;
  }
  .my-node-exit {
    opacity: 1;
  }
  .my-node-exit-active {
    opacity: 0;
    transition: opacity 200ms;
  }

@media only screen and (max-width: 768px) {
    .tabata-timer-timer{
        width: 250px;
        height: 250px;
    }

    .tabata-timer-timer>h1{
        font-size: 80px;
    }

    .tabata-timer-content{
        padding: 0;
    }
    
    .tabata-timer-wrapper{
        padding: 10px;
        margin: 0;
    }

    .tabata-timer{
        margin: 0;
    }


}
.switcher>input[type=checkbox]{
	height: 0;
	width: 0;
	visibility: hidden;
}

.switcher>label {
	cursor: pointer;
	text-indent: -9999px;
	width:var(--lbl-width); 
	height: var(--lbl-height);
	background: grey;
	display: block;
	border-radius: 100px;
	position: relative;
}

#switch{
    margin: 0;
}

.switcher>label:after {
	content: '';
	position: absolute;
	top: 5px;
	left: 5px;
	width: var(--lbl-after-height);
	height: var(--lbl-after-height);
	background: #fff;
	border-radius: 90px;
	transition: 0.3s;
}

.switcher>input:checked + label {
	background:var(--color);
}
.switcher>input+label{
    background:black;
}

.switcher>input:checked + label:after {
	left: calc(100% - 5px);
	transform: translateX(-100%);
}

.switcher>label:active:after {
	width: var(--lbl-active-after);
}

.result-tag {
  font-size: 15px;
}
.result-bar-titles {
  margin-top: 20px;
}

.result-bar-arrow {
  width: 50px;
  position: absolute;
  left:var(--left);
  bottom: 0;
  transition: 2.5s;
}
.result-bar {
  height: 30px;
  border-radius: 25px;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    164deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(5, 94, 126, 1) 54%
  );
}

.result-bar-arrow-wrapper {
  position: relative;
  height: 50px;
  
  --width: 50px;
  --width2: 25px;
  transition: all 0.5s;
}

.result-text{
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.result-text-title>h1{
    font-size: 90px;
}
.result-text-bmi>h1{
    font-size: 50px;
}

.result-text-comments>h1{
    font-size: 25px;
    font-weight: bold;
}

.result-text-comments>p{
    font-size: 15px;
    color: black;
}

.result-tag{
    font-family: var(--title-font);
    font-weight: bold;
}

@media only screen and (max-width: 768px) {
  .result-tag {
    font-size: 10px;
  }

  .result-wrapper {
    padding: 10px;
  }

  .result-bar-arrow {
    width: 50px;
    --width: 50px;
    --width2: 25px;
  }

  .result-bar-arrow-wrapper {
    --width: 50px;
    --width2: 50px;
  }

  .result-text-title>h1{
    font-size: 50px;
}
.result-text-bmi>h1{
    font-size: 50px;
}

.result-text-comments>h1{
    font-size: 25px;
    font-weight: bold;
}

.result-text-comments>p{
    font-size: 15px;
    color: black;
}
}

.bmi-content{
    padding: 50px;
}


input[type="radio"]{
    margin: 0 5px 0 0;
}
.bmi-form-title>h1{
    font-family: var(--title-font);
}

.gender-radio{
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;

}
.gender-radio>.gender-image{
    border-radius: 50%;
    border: .5px solid var(--principal-color-70);
    padding: 10px;
    min-height: 80px;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: middle;
    margin-bottom: 20px;
}
.gender-radio>.gender-image>img{
    width: 50px;

    
}
.label-radio:first-child>input{
    margin: 0 10px 0 0px;
}
.bmi-form-form{
    padding: 0 100px;
}


.form-check-input{
    width: 500px;
}
.range>h3{
    font-size: 20px;
    color: rgb(0, 0, 0,0.7)
}


.bmi-result-wrapper{
    background: var(--principal-color-70);
    border-radius: 50px;
    padding: 0;
}

.bmi-result-wrapper>div{
    
    padding: 20px;
}

.bmi-result-title>h1{
    color: white;
    font-size: 50px;
    font-family: var(--text-font);
}

.bmi-result-result>h1{
    font-size: 100px;
    font-family: var(--title-font);
    color: white;
}
.bmi-result-comentary>h1{
    color: white;
    font-size: 20px;
    font-weight: bold;
    font-family: var(--text-font);
}
.bmi-result-comentary>p{
    color: white;
    font-family: var(--text-font);
}

.bmi-text {
    padding: 20px 40px;
}

.bmi-intro-content{
    padding: 0;
    margin:0!important;
}

.bmi-intro-content-text{
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0!important;
    
}
.bmi-intro-content-text h1{
    font-size: 80px;
}

.bmi-intro-content-text p{
    font-size: 15px;
}

.bmi-intro-content-img-right {
    padding: 0;
    background-size: cover;
    background-position: center;
    min-height: 200px;
    margin: 0!important;
    -webkit-clip-path: polygon(45% 0, 100% 0, 100% 100%, 0 100%);
            clip-path: polygon(45% 0, 100% 0, 100% 100%, 0 100%);
}
.bmi-intro-content-img-left{
    padding: 0;
    background-size: cover;
    background-position: center;
    min-height: 200px;
    margin: 0!important;
    -webkit-clip-path: polygon(0 0, 45% 0, 100% 100%, 0 100%);
            clip-path: polygon(0 0, 45% 0, 100% 100%, 0 100%);
}

.bmi-intro-content:nth-child(1),.bmi-intro-content:nth-child(3){
    background-color: rgba(0, 0, 0, 0.9);
}
.bmi-intro-content:nth-child(1) h1,.bmi-intro-content:nth-child(3) h1,.bmi-intro-content:nth-child(1) p,.bmi-intro-content:nth-child(3) p{
    color: rgba(255, 255, 255, 0.973);
}
.bmi-intro-content:nth-child(2),.bmi-intro-content:nth-child(4){
    background-color: var(--principal-color-60)
}

.bmi-intro-content:nth-child(2) h1,.bmi-intro-content:nth-child(4) h1,.bmi-intro-content:nth-child(2) p,.bmi-intro-content:nth-child(4) p{
    color: rgba(0, 0, 0, 0.8)
}
@media only screen and (max-width: 768px) {
    .bmi-content{
        margin: 0;
        padding: 15px;
    }
    .bmi-result-title>h1{
        font-size:40px;
    }

    .bmi-result-result>h1{
        font-size:60px;
        font-family: var(--title-font);
        color: white;
    }

    .bmi-form-wrapper{
        padding: 0;
    }

    .bmi-form-form{
        padding: 0 ;
    }

    .bmi-text {
        padding: 0 10px;
    }

    .bmi-intro-content:nth-child(1) h1,.bmi-intro-content:nth-child(3) h1,.bmi-intro-content:nth-child(1) p,.bmi-intro-content:nth-child(3) p{
        color: rgba(255, 255, 255, 0.973);
    }
    .bmi-intro-content:nth-child(1),.bmi-intro-content:nth-child(3){
        background-color: rgba(0, 0, 0, 0.9);
    }
    .bmi-intro-content:nth-child(4),.bmi-intro-content:nth-child(2){
        background-color: var(--principal-color-60)
    }
    .bmi-intro-content:nth-child(2) h1,.bmi-intro-content:nth-child(4) h1,.bmi-intro-content:nth-child(4) p,.bmi-intro-content:nth-child(2) p{
        color: rgba(0, 0, 0, 0.8)
    }
    .bmi-intro-content-text h1{
        font-size: 20px;
    }
    
    .bmi-intro-content-text p{
        font-size: 12px;
    }

    .bmi-intro-content-img-left{
        order: 2;
    }
    
}

.contact-wrapper{
    padding: 20px 100px;
    margin: 0;
    background-size: cover;
    background-position: center;
    box-shadow: inset 0 0 0 1000px rgb(0, 0, 0,0.7);
}
.contact-form{
    padding: 40px 100px;
}

.contact-info{
    padding: 40px 0px!important;
}

.contact-form>form>div>input,.contact-form>form>div>textarea{
    border-radius: 25px;
}
.contact-info-socialmedia{
    margin-bottom: 20px;
}

.contact-socialmedia-icons{

    color: var(--secondary-color);
    background:  var(--principal-color) ;
  
      width: 80px;
      height: 80px;
      border-radius: 50%;
      text-align: center;
      line-height: 100px;
      vertical-align: middle;
      margin-left: 10px;
      /* padding: 30px; */
  }

  .contact-socialmedia-icons:hover{
    color: var(--principal-color) ;
    background: var(--secondary-color);
  }
  
  .contact-socialmedia-icons:focus{
    color: var(--principal-color) ;
    background: var(--secondary-color);
  }

.contact-info-personal>h2,.contact-info-personal>h3{
    margin: 0;
    font-family: var(--title-font);
    color: white;
}

.title>h1,.title>h2{
    color: white;
}

.contact-input{
    border-bottom: solid .5px white;
}
.contact-input:focus{
    background-color: var(--principal-color-30);
    border: solid .5px white;
}
@media only screen and (max-width: 768px) {
    .contact-form{
        padding: 40px 0 0 0;
    }

    .contact-wrapper{
        margin: 0;
        padding: 20px 30px;
    }

    .contact-info{
        padding: 0;
    }
    
  }
.ss-title {
  height: 500px;
  display: flex;
  justify-content: center;
  padding: 40px;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.7);
}
.ss-title > .ss-title-content > h1 {
  font-size: 80px;
  color: white;
}

.ss-title > .ss-title-content > img {
  width: 350px;
}

.ss-content {
  display: flex;
  justify-content: center;
  align-items: top;
}

.ss-content > div > h2 {
  text-align: justify;
  font-size: 15px;
  font-family: var(--text-font);
}

.ss-content > div > img {
  width: 150px;
}

.ss-content > div {
  padding: 20px 80px;
}
.ss-quote {
  padding: 50px !important;
  height: 500px;
  padding-left: -20px;
  background-color: var(--principal-color-70);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ss-quote > div > h2 {
  font-size: 30px;

  text-align: center;
  color: white;
  font-family: var(--title-font);
}
.ss-quote > div > p {
  font-size: 25px;
  font-weight: bold;
  color: white;
  text-align: center;
  font-family: var(--text-font);
}
.ss-quote > div > p > em {
  color: white;
}

@media only screen and (max-width: 768px) {
  .ss-title > .ss-title-content > h1 {
    font-size: 60px;
  }

  .ss-title > .ss-title-content > img {
    margin-top: 20px;
  }
  .ss-content {
    padding: 0;
    margin: 0;
  }
  .ss-content > div {
    padding: 10px 40px;
  }

  .ss-quote > div > h2 {
    font-size: 25px;
  }
  .ss-quote > div > p {
    font-size: 20px;
    padding-bottom: 0;
  }
}

.header-tarjeta-digital {
  height: 400px;
  position: relative;
  width: 100%;
}
.header-tarjeta-digital-bg {
  -webkit-clip-path: polygon(0% 0%, 100% 0, 100% 70%, 50% 100%, 0 70%);
          clip-path: polygon(0% 0%, 100% 0, 100% 70%, 50% 100%, 0 70%);
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: cover;
}

.main-tarjeta-digital {
  margin-top: 135px;
}

.tarjeta-digital-title {
  font-size: 60px;
}

.tarjeta-digital-sub-title {
  font-family: var(--text-font);
  margin: 0;
}

.main-tarjeta-digital > .contact > div {
  margin: 0 10px;
}
.main-tarjeta-digital > .contact > div > a {
  color: var(--principal-color);
  transition: 0.5s;
}
.main-tarjeta-digital > .direct-contact {
  margin: 20px 0;
}
.main-tarjeta-digital > .direct-contact > a {
  color: black;
  font-family: var(--text-font);
  margin: 10px 0;
  font-size: 20px;
}
.direct-contact-icon {
  color: var(--principal-color) !important;
  margin: 0 10px;
}

.main-tarjeta-digital > .contact > div > a:hover {
  color: black;
}
.main-tarjeta-digital > .contact {
  margin-top: 20px;
}
.header-tarjeta-digital > .header-tarjeta-digital-floating {
  position: absolute;
  height: 250px;
  width: 250px;
  background-color: black;
  border-radius: 50%;
  bottom: -125px;
  position: absolute;
  background-size: cover;
  background-size: center;
}

.tarjeta-digital-container {
  width: 50%;
  border: 1px solid var(--principal-color);
  margin: 30px 0;
  border-radius: 25px;
  overflow: hidden;
  background-color: white;
  box-shadow: -1px 3px 27px 11px rgba(0,0,0,0.75);
  -webkit-box-shadow: -1px 3px 27px 11px rgba(0,0,0,0.75);
  -moz-box-shadow: -1px 3px 27px 11px rgba(0,0,0,0.75);
}

.tarjeta-digital-wrapper {
    box-shadow: inset 0 0 0 1000px rgb(0, 0, 0, 0.1);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;


}
.about-me {
  padding: 0 20px;
  margin-top: 30px;
}
.about-me>p {
    color: rgb(0, 0, 0,0.9);
  }
@media only screen and (max-width: 768px) {
  .header-tarjeta-digital > .header-tarjeta-digital-floating {
    position: absolute;
    height: 200px;
    width: 200px;
    background-color: black;
    border-radius: 50%;
    bottom: -50px;
    position: absolute;
  }

  .main-tarjeta-digital {
    margin-top: 70px;
  }

  .tarjeta-digital-title {
    font-size: 40px;
  }

  .header-tarjeta-digital {
    height: 300px;
  }

  .about-me {
    padding: 20px;
  }

  .tarjeta-digital-container {
    width: 100%;
    border: 1px solid var(--principal-color);
    margin: 0;
    border-radius: 0;
    overflow: hidden;
    background-color: white;
  }
}

.cc-container {
  padding: 40px;
}

.cc-result > div {
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 50%;
  background-size: cover;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 15px;
}
.cc-result > div > * {
  color: white;
}

.cc-result > div > div > div > * {
  color: white;
}

@media only screen and (max-width: 768px) {
  .cc-result > div {
    width: 100%;
    height: 100%;
  }
}

.h-result {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.7);
  padding: 80px;
  text-align: center;
}

.h-result > .h-intro-result {
  color: white;
}

.h-intro {
  font-size: 50px !important;
  color: white;
}
.waterdrop {
  width: 200px;
  height: auto;
  margin-bottom: 20px;
}

._2iWL7 {
  width: 360px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 12px 24px 0px;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  background-color: rgb(255, 255, 255);
  transform: translate3d(0px, 0px, 0px);
  touch-action: auto;
  margin-right: 20px;
  margin-bottom: 10px;
  display: none;
}
._1fOYS {
  display: flex;
  -webkit-animation: _11FGp 250ms ease-in-out both;
          animation: _11FGp 250ms ease-in-out both;
}
._bvAzs {
  display: none;
}

@-webkit-keyframes _11FGp {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes _11FGp {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  ._2iWL7 {
    width: 100%;
    margin-right: 0;
  }
}

._2rVjT {
  background-color: rgb(3, 77, 104);
  display: flex;
}

._1w7Ch {
  color: #f0ffffa1;
  cursor: pointer;
  font-size: 1.3em;
  position: absolute;
  right: 10px;
  top: 10px;
}

._10MlI {
  align-items: center;
  align-self: center;
  background: #fff;
  border-radius: 50%;
  color: cadetblue;
  display: flex;
  font-size: 2em;
  height: 45px;
  margin: 5px;
  justify-content: center;
  padding: 10px;
  width: 45px;
}

._1Ixzy {
  color: rgb(255, 255, 255);
  margin-left: 16px;
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

._2C9-R {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  display: block;
}

._3B7os {
  font-size: 13px;
  line-height: 18px;
  margin-top: 4px;
}

._3YWHv {
  padding: 20px 20px 20px 20px;
  background-image: url('https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png');
  background-size: cover;
  position: relative;
  overflow: auto;
  max-height: 382px;
}

._nYEjV {
  padding: 7px 14px 6px;
  background-color: rgb(255, 255, 255);
  border-radius: 0px 8px 8px;
  position: relative;
  transition: all 0.3s ease 0s;
  transform-origin: center top;
  box-shadow: rgba(0, 0, 0, 0.13) 0px 1px 0.5px;
  margin-top: 4px;
  max-width: calc(100% - 120px);
}

._jryeF {
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.4);
}

._3CMpi {
  font-size: 14px;
  line-height: 19px;
  margin-top: 4px;
  color: rgb(17, 17, 17);
  white-space: pre-wrap;
}

._eRm_Y {
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 20px 20px 0;
  border-color: transparent #ffffff transparent transparent;
  position: absolute;
  left: -10px;
  top: 0;
}

._2x2HJ {
  display: flex;
  margin-top: 4px;
  font-size: 12px;
  line-height: 16px;
  color: rgba(17, 17, 17, 0.5);
  justify-content: flex-end;
}

._30mQ0 {
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
  padding: 15px;
}

._2-mAj {
  border-radius: 20px;
  padding: 12px 12px;
  border: none;
  margin-bottom: 10px;
  background-color: #fefefe;
}

._2W95M {
  padding: 6px 12px;
  border-radius: 20px;
  border: none;
  background:rgb(5,94,126);
  color: rgb(255, 255, 255);
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  cursor: pointer;
  margin: 0 20px;
  box-shadow: rgba(0, 0, 0, 0.13) 0px 5px 5px;
}

._1bpcM {
  align-items: center;
  align-self: flex-end;
  background-color:  rgb(5,94,126);
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 12px;
  color:rgb(255, 255, 255);
  cursor: pointer;
  display: flex;
  font-size: 2em;
  height: 64px;
  justify-content: center;
  margin-bottom: 20px;
  margin-right: 20px;
  margin-left: 20px;
  outline: transparent;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 64px;
  -webkit-box-align: center;
  -webkit-box-pack: center;
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  ._1bpcM {
    margin-right: 0;
  }
}

._YLx_9 {
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 15px;
  right: 25px;
  z-index: 9999;
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  ._YLx_9 {
    left: 50%;
    right: 0;
    transform: translateX(-50%);
    width: 90%;
  }
}

