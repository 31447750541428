@import url('./index.css');

/* 
.sobremi{
    position: relative;
}
.sobremi::before{
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,1) 14%, rgba(0,117,117,1) 95%);

  
    width: 100%;
    height: 100%;
  
    /* -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter:  opacity(0.8);
} */
.sobremi-p{
    padding: 0px 200px 20px 200px ;
    margin: 0;

}
.sobremi-p>p{
    font-size: 15px;
    text-align: justify;
    font-family: var(--text-font);
    color:black;


}
/* 
.sobremi-competitions>div>img{
    box-shadow: -6px 0px 101px 28px rgba(0,0,0,0.79);
    -webkit-box-shadow: -6px 0px 101px 28px rgba(0,0,0,0.79);
    -moz-box-shadow: -6px 0px 101px 28px rgba(0,0,0,0.79);
} */



.sobremi-competitions>h3{
    font-size: 20px;
    color: white;
    text-align: center;
    font-family: var(--title-font);
    text-shadow: 7px 2px 10px rgba(0, 0, 0, 1);
    background-size:cover;
    background-repeat: no-repeat;
    background-position: center;

 
}

.sobremi-competitions{

 padding:50px 50px;

 background-color: var(--principal-color);
 border-radius: 25px;
 background-size:cover;
 background-repeat: no-repeat;
 background-position: center;
    


 box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.7);


} 


.sobremi-evidence{

    padding-top: 40px;
    padding-bottom: 40px;


}
.sobremi-evidence>div>h1{
    font-size: 20px;
    font-family: var(--title-font);
    color: black;
  
}

.sobremi-evidence>div>img{
    width: 50%;
    
}
.sobremi-signature>h1{
    font-family: var(--signature-font) ;
    font-size:60px;
}


@media only screen and (max-width: 600px) {
    .sobremi-p{
        padding: 0px 30px 20px 30px;
    }

    
}

@media only screen and (max-width: 768px) {
    .sobremi-p{
        padding: 0px 30px 20px 30px;
    }

    .sobremi-evidence{
        padding-left: 0;
    }
    .sobremi-signature>h1{

        font-size:30px;
    }
}