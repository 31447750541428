
.title-book{
    margin: 0 90px;
    padding-top: 40px;
}

.book-card{
    position: relative;
    width: 200px;
}
.book-card>img{
    position: relative;
}

.book-card>div{
    transition: 0.2s ease-in;
    height: 0%;
    width: 100%;
    background-color: rgb(0, 0, 0,0.7);
    opacity: 0;
    position: absolute;
    bottom: 0;
    visibility: hidden;
}

.book-card>div>div{
    padding: 20px;
}

.book-card>div>div>p,.book-card>div>div>h3{
    color: white!important;
    text-align: center;
}


.book-card>div>div>h3{
    font-size: 20px;
    font-family: var(--title-font);
    text-transform: uppercase;
}

.book-card>div>div>p{
    font-size: 25px;
    font-family: var(--text-font);
}

.book-card:hover>div{
    opacity: 1;
    height: 100%;
    width: 100%;
    visibility: visible;

}
.book-card:hover div>div{
    padding: 20px;
}

@media only screen and (max-width: 768px) {

    .books>div{
        padding: 0;
    }

    .title-book{
        margin: 0 50px;
        padding-top: 40px;
        text-align: center;
    }
}