.header-tarjeta-digital {
  height: 400px;
  position: relative;
  width: 100%;
}
.header-tarjeta-digital-bg {
  clip-path: polygon(0% 0%, 100% 0, 100% 70%, 50% 100%, 0 70%);
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: cover;
}

.main-tarjeta-digital {
  margin-top: 135px;
}

.tarjeta-digital-title {
  font-size: 60px;
}

.tarjeta-digital-sub-title {
  font-family: var(--text-font);
  margin: 0;
}

.main-tarjeta-digital > .contact > div {
  margin: 0 10px;
}
.main-tarjeta-digital > .contact > div > a {
  color: var(--principal-color);
  transition: 0.5s;
}
.main-tarjeta-digital > .direct-contact {
  margin: 20px 0;
}
.main-tarjeta-digital > .direct-contact > a {
  color: black;
  font-family: var(--text-font);
  margin: 10px 0;
  font-size: 20px;
}
.direct-contact-icon {
  color: var(--principal-color) !important;
  margin: 0 10px;
}

.main-tarjeta-digital > .contact > div > a:hover {
  color: black;
}
.main-tarjeta-digital > .contact {
  margin-top: 20px;
}
.header-tarjeta-digital > .header-tarjeta-digital-floating {
  position: absolute;
  height: 250px;
  width: 250px;
  background-color: black;
  border-radius: 50%;
  bottom: -125px;
  position: absolute;
  background-size: cover;
  background-size: center;
}

.tarjeta-digital-container {
  width: 50%;
  border: 1px solid var(--principal-color);
  margin: 30px 0;
  border-radius: 25px;
  overflow: hidden;
  background-color: white;
  box-shadow: -1px 3px 27px 11px rgba(0,0,0,0.75);
  -webkit-box-shadow: -1px 3px 27px 11px rgba(0,0,0,0.75);
  -moz-box-shadow: -1px 3px 27px 11px rgba(0,0,0,0.75);
}

.tarjeta-digital-wrapper {
    box-shadow: inset 0 0 0 1000px rgb(0, 0, 0, 0.1);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;


}
.about-me {
  padding: 0 20px;
  margin-top: 30px;
}
.about-me>p {
    color: rgb(0, 0, 0,0.9);
  }
@media only screen and (max-width: 768px) {
  .header-tarjeta-digital > .header-tarjeta-digital-floating {
    position: absolute;
    height: 200px;
    width: 200px;
    background-color: black;
    border-radius: 50%;
    bottom: -50px;
    position: absolute;
  }

  .main-tarjeta-digital {
    margin-top: 70px;
  }

  .tarjeta-digital-title {
    font-size: 40px;
  }

  .header-tarjeta-digital {
    height: 300px;
  }

  .about-me {
    padding: 20px;
  }

  .tarjeta-digital-container {
    width: 100%;
    border: 1px solid var(--principal-color);
    margin: 0;
    border-radius: 0;
    overflow: hidden;
    background-color: white;
  }
}
