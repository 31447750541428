.result-tag {
  font-size: 15px;
}
.result-bar-titles {
  margin-top: 20px;
}

.result-bar-arrow {
  width: 50px;
  position: absolute;
  left:var(--left);
  bottom: 0;
  transition: 2.5s;
}
.result-bar {
  height: 30px;
  border-radius: 25px;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    164deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(5, 94, 126, 1) 54%
  );
}

.result-bar-arrow-wrapper {
  position: relative;
  height: 50px;
  
  --width: 50px;
  --width2: 25px;
  transition: all 0.5s;
}

.result-text{
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.result-text-title>h1{
    font-size: 90px;
}
.result-text-bmi>h1{
    font-size: 50px;
}

.result-text-comments>h1{
    font-size: 25px;
    font-weight: bold;
}

.result-text-comments>p{
    font-size: 15px;
    color: black;
}

.result-tag{
    font-family: var(--title-font);
    font-weight: bold;
}

@media only screen and (max-width: 768px) {
  .result-tag {
    font-size: 10px;
  }

  .result-wrapper {
    padding: 10px;
  }

  .result-bar-arrow {
    width: 50px;
    --width: 50px;
    --width2: 25px;
  }

  .result-bar-arrow-wrapper {
    --width: 50px;
    --width2: 50px;
  }

  .result-text-title>h1{
    font-size: 50px;
}
.result-text-bmi>h1{
    font-size: 50px;
}

.result-text-comments>h1{
    font-size: 25px;
    font-weight: bold;
}

.result-text-comments>p{
    font-size: 15px;
    color: black;
}
}
