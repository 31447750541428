.weight-loss-intro > h2{
    font-weight: bold;
}

.rendimiento-deportivo{
    box-shadow: inset 0 0 0 2000px rgba(0,0,0,0.3);
    height: 100vh;
}

.rendimiento-deportivo h2{
    text-align: center;
    color: white;
}
.rendimiento-deportivo p{
    color: white!important;
}
.rendimiento-deportivo{
    background-color: rgba(0,0,0,0.7)  ;
}

.rendimiento-deportivo-categories{
    background-position: center;
    background-size: cover;

    box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.7);
    padding: 20px;

    height: 450px;
}

.rendimiento-deportivo-categories h2 , .rendimiento-deportivo-categories p{
    color: white;
}

.rendimiento-deportivo-categories h2{
    text-align: center;
}

.rendimiento-deportivo-platform h1{
    color: white;
}