
.contact-wrapper{
    padding: 20px 100px;
    margin: 0;
    background-size: cover;
    background-position: center;
    box-shadow: inset 0 0 0 1000px rgb(0, 0, 0,0.7);
}
.contact-form{
    padding: 40px 100px;
}

.contact-info{
    padding: 40px 0px!important;
}

.contact-form>form>div>input,.contact-form>form>div>textarea{
    border-radius: 25px;
}
.contact-info-socialmedia{
    margin-bottom: 20px;
}

.contact-socialmedia-icons{

    color: var(--secondary-color);
    background:  var(--principal-color) ;
  
      width: 80px;
      height: 80px;
      border-radius: 50%;
      text-align: center;
      line-height: 100px;
      vertical-align: middle;
      margin-left: 10px;
      /* padding: 30px; */
  }

  .contact-socialmedia-icons:hover{
    color: var(--principal-color) ;
    background: var(--secondary-color);
  }
  
  .contact-socialmedia-icons:focus{
    color: var(--principal-color) ;
    background: var(--secondary-color);
  }

.contact-info-personal>h2,.contact-info-personal>h3{
    margin: 0;
    font-family: var(--title-font);
    color: white;
}

.title>h1,.title>h2{
    color: white;
}

.contact-input{
    border-bottom: solid .5px white;
}
.contact-input:focus{
    background-color: var(--principal-color-30);
    border: solid .5px white;
}
@media only screen and (max-width: 768px) {
    .contact-form{
        padding: 40px 0 0 0;
    }

    .contact-wrapper{
        margin: 0;
        padding: 20px 30px;
    }

    .contact-info{
        padding: 0;
    }
    
  }