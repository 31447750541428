.dpaleo-intro {
  background-position: center;
  background-size: cover;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.7);
}

.dpaleo-intro > p,
.dpaleo-intro > h1 {
  color: white;
}

.dpaleo-intro {
  padding: 50px 200px !important;
}

.dpaleo-intro > h1 {
  font-size: 80px;
}

.dpaleo-benefits {
  background-color: rgba(0, 0, 0, 0.7);
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.7);
  background-position: center;
  background-size: cover;

  height: 400px;
}

.dpaleo-benefits > ul > li > p,
.dpaleo-benefits > h2 {
  color: white;
}

.dpaleo-benefits > h2 {
  font-size: 60px;
}
.dpaleo-benefits > ul > li {
  font-size: 20px;
  text-align: justify;
  display: flex;
  justify-content: center;
  align-self: center;
  flex-direction: column;
}

.dpaleo-benefits > ul > li > img {
  width: 60px;
  align-self: center;
}
.dpaleo-recomend {
  background-color: rgba(0, 0, 0, 0.9);
  background-size: cover;
  background-position: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.7);
  text-align: center;
}

.dpaleo-recomend > p,
.dpaleo-recomend > h2,
.dpaleo-recomend {
  color: white;
}

.dpaleo-recomend > p {
  text-align: center;
}

.dpaleo-recomend > ul > li {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: justify;
}

.dpaleo-recomend > ul > li > img {
  margin-bottom: 30px !important;
  width: 100px;
}

@media only screen and (max-width: 768px) {
  .dpaleo-intro {
    padding: 50px 20px !important;
  }

  .dpaleo-intro > h1 {
    font-size: 60px;
  }

  .dpaleo-benefits {
    height: 900px;
  }
}
