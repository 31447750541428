.hs-intro {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 100px !important;
  background-position: center;
  background-size: cover;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.8);
}

.hs-intro > h1 {
  font-size: 30px;
  color: white;
}

.hs-outro > h2 {
  font-size: 25px;
  color: white;
}

.hs-outro {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 25px !important;
  background-position: center;
  background-size: cover;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.8);
}

.platform-button > img {
  width: 100px;
  height: auto;
  transition: 0.5s;
}

.platform-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10%;
  position: relative;
  transition: 0.5;
}

.platform-button > p {
  font-family: var(--title-font);
}

.platform-button:hover > img {
  transform: rotate(360deg);
}

.platform-button:hover > img {
  transform: rotate(360deg);
}
.hs-values-content {
  padding: 20px !important;
}

.hs-values-img {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
}

.hs-values:nth-child(even) {
  background: rgba(0, 0, 0, 0.8);
}

.hs-values:nth-child(odd) {
  background: var(--principal-color-70);
}

.hs-values:nth-child(odd) > .hs-values-img {
  order: 2;
}
.hs-values {
  width: 100%;
  height: 350px;
  margin: 0 !important;
}
.hs-values > div > h2,
.hs-values > div > p {
  color: white;
}

.hs-values > div > h2 {
  font-family: var(--title-font);
  text-transform: uppercase;
}

@media only screen and (max-width: 768px) {
  .hs-intro > h1 {
    font-size: 20px;
    color: white;
  }
  .hs-intro {
    padding: 0 20px !important;
  }

  .hs-values {
    width: 100%;
    height: 700px;
    margin: 0 !important;
  }
  .hs-values:nth-child(even) > .hs-values-img {
    order: 2;
  }
  .hs-outro > h2 {
    font-size: 15px;
    color: white;
    margin: 10px;
  }

  .option-text {
    width: 100vw;
  }

  .hs-values-img {
    background-position: center;
    background-size: cover;
    height: 300px;
    align-self: flex-end;
  }
}
