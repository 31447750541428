.weight-loss-intro {
  background-position: center;
  background-size: cover;

  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.7);
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.weight-loss-intro > p {
  color: white;
}

.weight-loss-intro > h1,
.weight-loss-intro > h2 {
  color: white;
}

.option-text {
  width: 100vw;
}

.weight-loss-content {
  padding: 0 !important;
  margin: 0 !important;
  background-color: rgba(0, 0, 0, 0.9);
  height: 350px;
}

.weight-loss-content > div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  background-position: center;
  background-size: cover;
  /* border: 1px black solid; */
  /* 
  box-shadow: -1px 14px 9px -3px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -1px 14px 9px -3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 14px 9px -3px rgba(0, 0, 0, 0.75); */
}

.weight-loss-content > div > * {
  color: white;
}

.weight-loss-function {
  margin: 0 !important;
}

.weight-loss-function > li {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
}

.weight-loss-function > li {
  background-position: center;
  background-size: cover;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.7);
}

.weight-loss-function > li > * {
  color: white;
}

/* .weight-loss-function > li:nth-child(1),
.weight-loss-function > li:nth-child(3) {
  background: rgba(0, 0, 0, 0.9);
}
.weight-loss-function > li:nth-child(2) {
  background: var(--principal-color-70);
} */

/* .weight-loss-function > li:nth-child(2) > p,
.weight-loss-function > li:nth-child(2) > h3 {
  color: black;
} */

.weight-loss-function > li > h3 {
  font-size: 40px;
}

.weight-loss-function > li > p {
  font-size: 20px;
  text-align: center;
}

/* .weight-loss-function > li:nth-child(1) > p,
.weight-loss-function > li:nth-child(3) > p {
  color: white;
}

.weight-loss-function > li:nth-child(1) > h3,
.weight-loss-function > li:nth-child(3) > h3 {
  color: white;
} */

.weight-loss-content-img {
  clip-path: polygon(0 1%, 100% 0%, 100% 100%, 50% 100%);
  min-height: 200px;
}
.img-2 {
  clip-path: polygon(0 1%, 100% 0%, 50% 100%, 0 100%);
}

@media only screen and (max-width: 768px) {
  .weight-loss-intro {
    height: 500px;
  }

  .weight-loss-intro > p {
    font-size: 12px;
  }

  .weight-loss-content > div {
    margin: 0 !important;
  }

  .weight-loss-content {
    height: 100%;
  }
  .weight-loss-function {
    padding: 0;
  }

  .option-text > h1 {
    font-size: 30px;
  }

  .weight-loss-content-text {
    order: 1 !important;
  }

  .weight-loss-content-img {
    clip-path: polygon(0 1%, 100% 0%, 100% 100%, 0 100%);
  }
}
