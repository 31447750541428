@import url("./index.css");

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.bg-image {
  height: 500px;
  position: relative;
}

.bg-body {
  z-index: 2;
}

.header-body {
  z-index: 2;
}

.header-body > h1 {
  font-size: 50px;
  color: white;
  font-family: var(--title-font);
  text-transform: uppercase;
}

.header-body > h2 {
  font-size: 20px;
  color: white;
}

.homepage-header {
  padding: 0 300px;
}

/* style={{backgroundImage: "url(img/homepage-header.jpg)", filter:"brightness(70%)"}} */
.bg-image::before,
.bg-image-sticky::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;

  display: block;
  background-image: var(--bg);
  background-size: cover;
  background-repeat: no-repeat;

  width: 100%;
  height: 100%;

  /* -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px); */
  filter: brightness(40%);
}

.bg-image::before {
  background-position: 0 25%;
}

.bg-image-sticky {
  position: relative;
  background-position: center;
}

.bg-image-sticky::before {
  background-attachment: fixed;
}

.button-primary {
  background-color: var(--principal-color);
}
.button-secondary {
  background-color: white;
  transition: 0.5s;
  border-radius: 25px;
}

.button-secondary:hover {
  background-color: black !important;
  color: white;
}

.button-secondary:active {
  background-color: black !important;
  color: white;
}
.button-secondary-invert {
  background-color: black;
  color: white;
  transition: 0.5s;
  border-radius: 25px;
}

.button-secondary-invert:hover {
  background-color: white !important;
  color: black;
}

.button-secondary-invert:focus {
  background-color: white !important;
  color: black;
}

/* .button-secondary-invert:visited{
  background-color: white!important;
  color: black;
}  */

/* .button-secondary-invert:visited{
  background-color: white!important;
  color: black;
} */
.button-primary:hover {
  background-color: var(--secondary-color);
}

.button-primary:disabled {
  background-color: var(--principal-color-30);
}

.large-button {
  display: flex;
  align-items: center;
  justify-content: center;
}
.shadow {
  box-shadow: -6px 0px 101px 28px rgba(0, 0, 0, 0.79);
  -webkit-box-shadow: -6px 0px 101px 28px rgba(0, 0, 0, 0.79);
  -moz-box-shadow: -6px 0px 101px 28px rgba(0, 0, 0, 0.79);
}

.socialmedia-icons {
  box-shadow: -6px 0px 101px 28px rgba(0, 0, 0, 0.79);
  -webkit-box-shadow: -6px 0px 101px 28px rgba(0, 0, 0, 0.79);
  -moz-box-shadow: -6px 0px 101px 28px rgba(0, 0, 0, 0.79);
  color: var(--secondary-color);
  background: var(--principal-color);

  width: 150px;
  height: 150px;
  border-radius: 50%;
  text-align: center;
  line-height: 100px;
  vertical-align: middle;
  padding: 30px;
}

.socialmedia-icons:hover {
  color: var(--principal-color);
  background: var(--secondary-color);
}

.socialmedia-icons:focus {
  color: var(--principal-color);
  background: var(--secondary-color);
}

.socialmedia-icons-text {
  font-size: 50px;
  color: white;
}

.short-about-me {
  position: relative;
  padding: 60px;
  margin: 0;
  box-shadow: inset 0 0 0 1000px var(--principal-color-70);
  overflow: hidden;
}

.short-about-me > div {
  padding: 0 200px;
}

.short-about-me > div > img {
  border-radius: 50%;
}

.short-about-me > div > p {
  color: var(--third-color);
  text-align: justify;
  font-family: var(--text-font);
}

.short-about-me > div > h1 {
  color: var(--third-color);
  text-align: justify;
  font-family: var(--title-font);
  text-transform: uppercase;
  font-size: 60px;
  text-align: center;
}

.short-about-me-seemore {
  border-radius: 50%;
  margin: 10px;
  position: relative;
}

.short-about-me-seemore > img {
  border-radius: 50%;
  position: relative;
}

.short-about-me-seemore-inside {
  content: "";
  background-color: black;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: 0%;

  transition: 0.2s ease-in;
}

.short-about-me-seemore-inside:hover {
  content: "";
  background-color: rgb(0, 0, 0, 0.7);
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: 100%;
  z-index: 2;
}

.help-card {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: inset 0 0 0 1000px rgb(0, 0, 0, 0.7);
  border-radius: 10px;

  transition: ease-in 0.3s;
}
/* .help-card:hover{
  box-shadow: inset 0 0 0 1000px var(--principal-color-70);
} */
.help-card > h1 {
  font-size: 150%;
  color: white;
  font-family: var(--nav-font);
  text-transform: uppercase;
  padding: 0 130px;
}
.help-card-title > h1 {
  font-size: 65px;
  font-family: var(--title-font);
  text-align: center;
  text-transform: uppercase;
}

.help-card-wrapper {
  background-color: #f3efee;
}

.footer-title {
  margin: 30px;
}

.footer-title > a {
  font-size: 30px;
  text-transform: uppercase;
  font-family: var(--logo-font);
  text-decoration: none;
}

.footer-title > p {
  font-size: 12px;
  font-family: var(--logo-font);
  text-align: justify;
  text-decoration: none;
  color: white;
}
.footer-nav > li > a {
  color: white;
}
.footer-title-social-media > h1 {
  font-size: 30px;
  color: white;
  font-family: var(--nav-font);
}

.footer-social-media {
  color: white;
}

.divider-large {
  padding: 20px;
  border-right: 2px solid white;
  height: 40%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  .top-navbar {
    display: none;
  }

  .divider-large {
    border-right: none;
  }
}

@media only screen and (max-width: 768px) {
  .help-card-title > h1 {
    font-size: 40px;
  }
  .short-about-me > div > h1 {
    font-size: 40px;
  }
  .short-about-me > div {
    padding: 0;
  }

  .short-about-me {
    padding: 20px;
  }

  .homepage-header {
    padding: 0 30px;
  }

  .header-body h1 {
    font-size: 25px;
  }

  .header-body > h2 {
    font-size: 15px;
  }

  .bg-image::before {
    background-position: center;
  }

  .bg-image {
    height: 500px;
  }
}
