.aintermitente-intro {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-position: center;
  background-size: cover;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.7);
  height: 400px;
}

.aintermitente-intro > * {
  color: white;
  padding: 0 200px;
  font-size: 15px;
  text-align: justify;
}

.aintermitente-intro > ul {
  padding: 0 400px;
}
.aintermitente-intro > ul > li {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.aintermitente-intro > ul > li > img {
  width: 50px;
}

.aintermitente-intro > h2 {
  font-size: 40px;
}

.aintermitente-content {
  background-size: cover;
  background-position: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.7);
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.aintermitente-content > * {
  color: white;
}

.aintermitente-tipos {
  background-position: center;
  background-size: cover;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.7);
}
.aintermitente-tipos > * {
  color: white;
}

.aintermitente-tipos > ul > li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}
.aintermitente-tipos > ul > li > h3 {
  color: white;
  font-weight: bold;
}

.aintermitente-beneficios {
  background-size: cover;
  background-position: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.7);
}

.aintermitente-beneficios > * {
  color: white;
}

.aintermitente-beneficios > ul > li {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.aintermitente-beneficios > ul > li > img {
  width: 50px;
}

.aintermitente-beneficios > ul {
  margin: 100px 0;
}

.aintermitente-beneficios > ul > li > p {
  font-family: var(--title-font);
}

.aintermitente-platform {
  margin: 100px 0;
}
.aintermitente-platform > * {
  color: white;
}

.aintermitente-platform > h1 {
  font-size: 30px;
}
@media only screen and (max-width: 768px) {
  .aintermitente-intro > * {
    color: white;
    padding: 0 20px;
    font-size: 15px;
    text-align: justify;
  }

  .aintermitente-intro > ul {
    padding: 0 20px;
  }

  .aintermitente-intro {
    height: auto;
  }

  .aintermitente-content {
    height: auto;
  }
}
