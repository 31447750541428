.tabata-timer-wrapper{
    padding: 50px;
}



.tabata-timer-timer{
    background-color: var(--principal-color-70);

    width: 400px;
    height: 400px;
    margin: 0!important;
    border-radius: 100%;
 
}


.tabata-timer-timer>h1{
    color:white;
    margin: 0;
    font-family: var(--title-font);
    font-size: 150px;
}

.tabata-timer-mov{
    padding-top: 20px;
}

.tabata-timer-mov>h1{
    font-family: var(--title-font);
}

.tabata-timer-round>h1{
    font-family: var(--text-font);
    font-weight: bold;
}

.time-form-wrapper{
    padding: 40px;
}

.time-form-item{
    margin-bottom: 20px;
}
.time-form-title>h1{
    font-family: var(--title-font);
    font-size: 65px;
}

.uk-form-label{
    font-size: 20px;
}

.my-node-enter {
    opacity: 0;
  }
  .my-node-enter-active {
    opacity: 1;
    transition: opacity 200ms;
  }
  .my-node-exit {
    opacity: 1;
  }
  .my-node-exit-active {
    opacity: 0;
    transition: opacity 200ms;
  }

@media only screen and (max-width: 768px) {
    .tabata-timer-timer{
        width: 250px;
        height: 250px;
    }

    .tabata-timer-timer>h1{
        font-size: 80px;
    }

    .tabata-timer-content{
        padding: 0;
    }
    
    .tabata-timer-wrapper{
        padding: 10px;
        margin: 0;
    }

    .tabata-timer{
        margin: 0;
    }


}