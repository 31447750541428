.option-wrapper {
  overflow-x: hidden;
  height: 100%;
  transition: all 2s;
}
.single-option-wrapper {
  padding: 20px;
}
.option-content {
  height: 100%;
  overflow-y: scroll;
  transition: all 2s;
}

.option-content > h1 {
  margin: 10px;
  font-size: 90px;
}

.option-content > h1 > img {
  width: 70px;
  cursor: pointer;
}
.option-content-header {
  box-shadow: inset 0 0 0 1000px rgb(0, 0, 0, 0.7);
  height: 400px !important;
  background-size: cover;
  background-position: center;
  flex-direction: column;
}

.option-content-header > p {
  padding: 0 200px;

  color: white;
}

.option-content-header > h1 {
  color: white;
  cursor: pointer;
}

.option-content-header > h1::before {
  content: "<";
}
.option-content > div {
  width: 100%;
  margin: 0;
  height: 100%;
  padding: 20px;
}
.option-content::-webkit-scrollbar {
  display: none;
}

.option-text {
  flex-direction: column;
}
.option-text > div {
  text-align: center;
  padding: 0;
  margin: 0;
}

.muscle-list {
  margin: 0 !important;
  background-position: center;
  background-size: cover;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.6);
}

.muscle-list > * {
  color: white !important;
}
.option-text > h1 {
  padding: 40px;
}

.option-text > p {
  padding: 0 200px;
  text-align: justify;
  color: black;
  font-size: 20px;
}

.option-text > a {
  border-radius: 25px;
}
.option {
  margin: 0;
  padding: 0;
  min-height: 300px;
  cursor: pointer;
  border-radius: 25px;
  background-size: cover;
  background-position: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.6);
  transition: all 0.5s;
}
.option:hover {
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.3);
}
.option > h1 {
  padding: 0;
  color: white;
  text-align: center;
}
.dark > h2,
.dark > p {
  color: white;
}
.dark > h2,
.blue > h2 {
  text-transform: uppercase;
}
.dark {
  background-color: rgba(0, 0, 0, 0.9);

  padding: 20px;
}

.blue {
  background-color: var(--principal-color-70);
  padding: 20px;
}
.blue > h2,
.blue > p {
  color: rgba(0, 0, 0, 0.9);
}

.muscle {
  padding: 0;
}

.muscle-list > img {
  width: 100px;
}
.muscle-list-wrapper {
  margin: 0 !important;
  padding: 0 !important;
  height: 500px;
}
.muscle-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px;
}
.muscle-list > ul {
  list-style-type: none;
  display: flex;
  padding: 0;
}

.muscle-list > ul > li {
  background-color: white;
  color: black;
  margin: 10px;
  border-radius: 25px;
  padding: 10px;
  font-family: var(--title-font);
  display: flex;
  justify-content: center;
  align-items: center;
}

.musculacion-platform {
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px !important;
  flex-direction: column;
  box-shadow: inset 0 0 0 1000px rgb(0, 0, 0, 0.5);
}

.musculacion-platform > * {
  color: white;
}
@media only screen and (max-width: 768px) {
  .option-content > h1 {
    margin: 10px;
    font-size: 40px;
  }
  .option-text > p {
    padding: 0 35px;
    text-align: justify;
  }
  .option-content-header > p {
    padding: 0 15px;
  }
  .option-content > h1 > img {
    width: 45px;
    cursor: pointer;
  }

  .dark > ul > li {
    font-size: 13px;
  }

  .muscle-list-wrapper {
    height: auto;
  }
}
