.h-result {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.7);
  padding: 80px;
  text-align: center;
}

.h-result > .h-intro-result {
  color: white;
}

.h-intro {
  font-size: 50px !important;
  color: white;
}
.waterdrop {
  width: 200px;
  height: auto;
  margin-bottom: 20px;
}
