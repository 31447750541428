.switcher>input[type=checkbox]{
	height: 0;
	width: 0;
	visibility: hidden;
}

.switcher>label {
	cursor: pointer;
	text-indent: -9999px;
	width:var(--lbl-width); 
	height: var(--lbl-height);
	background: grey;
	display: block;
	border-radius: 100px;
	position: relative;
}

#switch{
    margin: 0;
}

.switcher>label:after {
	content: '';
	position: absolute;
	top: 5px;
	left: 5px;
	width: var(--lbl-after-height);
	height: var(--lbl-after-height);
	background: #fff;
	border-radius: 90px;
	transition: 0.3s;
}

.switcher>input:checked + label {
	background:var(--color);
}
.switcher>input+label{
    background:black;
}

.switcher>input:checked + label:after {
	left: calc(100% - 5px);
	transform: translateX(-100%);
}

.switcher>label:active:after {
	width: var(--lbl-active-after);
}
