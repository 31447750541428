.sliderForm {
    width: var(--width);
    display: flex;
    height: 100%;
    
  }
  
  .sliderForm > div {
    flex-direction: column;
    min-width: var(--slides);
  }
  
  @keyframes fade {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  