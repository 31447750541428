.ss-title {
  height: 500px;
  display: flex;
  justify-content: center;
  padding: 40px;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.7);
}
.ss-title > .ss-title-content > h1 {
  font-size: 80px;
  color: white;
}

.ss-title > .ss-title-content > img {
  width: 350px;
}

.ss-content {
  display: flex;
  justify-content: center;
  align-items: top;
}

.ss-content > div > h2 {
  text-align: justify;
  font-size: 15px;
  font-family: var(--text-font);
}

.ss-content > div > img {
  width: 150px;
}

.ss-content > div {
  padding: 20px 80px;
}
.ss-quote {
  padding: 50px !important;
  height: 500px;
  padding-left: -20px;
  background-color: var(--principal-color-70);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ss-quote > div > h2 {
  font-size: 30px;

  text-align: center;
  color: white;
  font-family: var(--title-font);
}
.ss-quote > div > p {
  font-size: 25px;
  font-weight: bold;
  color: white;
  text-align: center;
  font-family: var(--text-font);
}
.ss-quote > div > p > em {
  color: white;
}

@media only screen and (max-width: 768px) {
  .ss-title > .ss-title-content > h1 {
    font-size: 60px;
  }

  .ss-title > .ss-title-content > img {
    margin-top: 20px;
  }
  .ss-content {
    padding: 0;
    margin: 0;
  }
  .ss-content > div {
    padding: 10px 40px;
  }

  .ss-quote > div > h2 {
    font-size: 25px;
  }
  .ss-quote > div > p {
    font-size: 20px;
    padding-bottom: 0;
  }
}
