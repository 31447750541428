@import url('./index.css');

.top-navbar{
    height: 40px;
    background-color: black;
    list-style-type: none;
    margin: 0;
}

.top-navbar-item>a{
color: white;
 margin: 0;

 text-decoration: none;
}
.top-navbar-item{
    margin:0;
}


.logo-navbar:hover{
    text-decoration: none;
}
.logo{
    font-family: var(--logo-font);
    text-decoration: none;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: bold;
  }
  
  .uk-navbar-item, .uk-navbar-nav>li>a, .uk-navbar-toggle {
      min-height: 60px;
  }
.bottom-navbar-nav>li>a{
    font-size: 20px;

    text-transform: uppercase;
    font-family: var(--nav-font);
    font-weight: bold;
}

#offcanvas-nav-primary>div>ul>li>a{
    color: white;
}
.uk-navbar-dropdown-nav>li>a{
    font-size: 15px!important;

    text-transform: capitalize;
    font-family: var(--nav-font);
}


.uk-nav-sub>li>a{
    font-size: 15px;
}
.divider{
    border-right: 2px solid white;
  height: 20px;
}

#bottom-navbar{
    background-color: white !important;
}

.uk-navbar-left a{
    color: black !important;
}

.uk-navbar-left>ul>li> a:hover{
    background-color: black;
    color: white !important;
}




.uk-navbar-dropdown{
    background-color: black;

}

.uk-navbar-dropdown a{
    color: white!important;
    
}

.uk-navbar-dropdown a:hover{
    color: grey!important;
    
}

.uk-parent>a{
    color: white!important;
}

.uk-offcanvas-bar{
    background-color: black;
}



@media only screen and (max-width: 600px) {
    .top-navbar{
        display:none;
    }
    .logo{
        font-size: 15px;
    }
}
