.zona-intro {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;

  box-shadow: inset 0 0 0 1000px rgb(0, 0, 0, 0.7);
  background-position: center;
  background-size: cover;
}

.zona-text {
  padding: 0 !important;
}

.zona-text > div {
  padding: 20px;
  background-position: center;
  background-size: cover;
  height: 400px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.5);
}

.dzona-platform {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 600px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.5);
  width: 100%;
}

.dzona-platform > h1 {
  color: white;
  font-size: 40px !important;
}

.dzona-platform > h1 {
  padding: 0 100px;
  font-size: 20px;
  text-align: center;
}

.zona-text > div > p {
  color: white;
  font-size: 16px;
}

.zona-text > div > h1 {
  color: white;
}
.dzona-list {
  padding: 0 50px !important;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.8);
}

.dzona-list > h1 {
  margin-bottom: 50px;
  color: white;
  font-size: 90px;
}

.dzona-list > ul > li > p {
  color: white;
  text-align: justify;
  font-size: 20px;
}

.dzona-list > ul > li > img {
  width: 80px;
}
.zona-text > p {
  font-size: 15px;
  color: black;
}

.platform {
  flex-direction: column;
}

.wave-d {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 70px;
  width: 100%;
  background: dodgerblue;
  z-index: -1;
}
.wave::before {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100% 90%;
  width: 51%;
  height: 75px;
  background-color: white;
  right: 0px;
  top: 35px;
}
.wave::after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100% 90%;
  width: 51%;
  height: 75px;
  background-color: dodgerblue;
  left: -8px;
  top: 25px;
}

.zona-intro > h1 {
  color: white;
  font-size: 50px;
  text-align: center;
}

.option-text {
  padding: 0 !important;
}

@media only screen and (max-width: 768px) {
  .zona-intro > h1 {
    font-size: 30px;
  }

  .dzona-platform > h1 {
    padding: 0 30px;
    font-size: 20px !important;
    text-align: center;
  }

  .dzona-list {
    padding: 0 20px !important;
    height: 1200px;
  }

  .dzona-list > h1 {
    font-size: 60px;
  }

  .zona-text > div {
    margin-top: 0 !important;
  }
}
